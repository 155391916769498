<template>
  <div class="step-component row">
    <div class="col-sm-11">
      <div class="row mb-2">
        <div class="col-sm-2 text-right leftFloat left-text">房间</div>

        <div class="col-sm-8 row" style="margin-left: 0;">
          <RoomSelect
            class="leftFloat"
            :selected-rooms="roomIds"
            @change-rooms="changeContractRooms"
          ></RoomSelect>
        </div>
      </div>
      <!-- XXX: 不再使用(不要再解开这个注释了,保留是为了防止以后生变)
                <div class="row mb-3">
                <div class="col-sm-2 mr-1 text-right">
                    房间租金
                </div>
                <div class="col-sm-9 input_">
                    <input type="number"
                        v-model="rentAmount"
                        placeholder="1-999999"
                        class="custom-input" style="width: 130px;">
                        元/
                        <select class="custom-input input_1"  v-model="rentTimeunit">
                            <option v-for="unit in timeUnit" :key="unit.id"
                                :value="unit.id">
                                {{unit.name}}
                            </option>
                        </select>
                </div>
            </div> -->
      <div class="row mb-3">
        <div class="col-sm-2 mr-1 text-right">合同金额</div>
        <div class="col-sm-9" style="max-width: 80%;flex: 1 0 auto;">
          <div>
            <PayPhaseComponent
              :data="installments"
              :allow-multiple-phase="true"
              @change-pay-pause="changeInstallments"
            ></PayPhaseComponent>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-2 mr-1 text-right">应付租金</div>
        <div class="col-sm-9" style="margin: 0px 0px 4px;margin-left: 10px;">
          <template v-if="rentAmount">
            <span style="color:#000">{{ rentAmount }}元</span>
          </template>
          <template v-else>-</template>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-2 mr-1 text-right">房间押金</div>
        <div class="col-sm-9">
          <div>
            <PayPhaseComponent
              :data="depositInfos"
              :allow-multiple-phase="false"
              :have-deduction="false"
              @change-pay-pause="changeDepositInfos"
            ></PayPhaseComponent>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PayPhaseComponent from "@/components/PayPhaseComponent.vue";
import RoomSelect from "@/components/RoomSelect.vue";
import { TimeUnit } from "../../common/commonConst";
import { calcContractRentUrl } from "@/requestUrl";

export default {
  props: {
    roomIds: Array,
  },
  name: "ContractSecondStep",
  components: {
    PayPhaseComponent,
    RoomSelect,
  },
  data() {
    return {
      timeUnit: [
        { id: TimeUnit.DAY, name: "日" },
        { id: TimeUnit.WEEK, name: "周" },
        { id: TimeUnit.MONTH, name: "月" },
        { id: TimeUnit.QUARTERLY, name: "季" },
        { id: TimeUnit.YEAR, name: "年" },
      ],
      rentAmount: null,
      rentTimeunit: TimeUnit.MONTH,
      contractRooms: [],
      installments: [
        {
          deduction: false,
          paymentDate: "",
          amount: "",
          deductionAmount: "",
          comment: "",
        },
      ],
      depositInfos: [
        {
          deduction: false,
          paymentDate: "",
          amount: "",
          deductionAmount: "",
          comment: "",
        },
      ],

      // 选中的房间信息
      selectedRooms: [],
    };
  },
  methods: {
    /**
     * 修改签约合同的房间
     * @param {Object} roomInfo 选中的所有房间信息
     *  */
    changeContractRooms(roomInfo) {
      const { roomIds, rooms } = roomInfo;
      this.contractRooms = roomIds.map((val) => {
        return { roomId: val };
      });
      this.selectedRooms = rooms;
    },
    // 修改房间押金
    changeDepositInfos(data) {
      this.depositInfos = data;
    },
    // 修改合同金额
    changeInstallments(data) {
      this.installments = data;
      let isCalc = true;
      const installments = data.map((val) => {
        if (val.amount == null || val.amount == "") {
          isCalc = false;
        }
        const installment = { ...val };
        installment.deductionAmount = val.deductionAmount;
        installment.amount = val.amount;
        return installment;
      });
      if (isCalc) {
        this.calcRentAmount(installments);
      }
    },
    /**
     * 计算应付租金
     * @param {Array} installments 支付信息
     *  */
    calcRentAmount(installments) {
      this.$fly.post(calcContractRentUrl, { installments }).then((res) => {
        if (res.code != 0) {
          return;
        }
        this.rentAmount = res.data;
      });
    },
    getData() {
      // const installments = this.$refs.installments.returnPayPhase();
      const { rentAmount, installments, contractRooms, selectedRooms } = this;
      const {
        paymentDate: depositPaymentTime,
        amount: deposit,
      } = this.depositInfos[0];

      return {
        rentAmount,
        installments,
        contractRooms,
        selectedRooms,
        deposit,
        depositPaymentTime,
      };
    },
  },
  created() {},
};
</script>

<style>
.contract-modal-content .col-sm-9 {
  padding-left: 0;
}
.backgauge1 {
  margin: 0 5px 0 10px !important;
}
.custom-input {
  margin: 0 5px 0 5px !important;
}

select,
option {
  color: #999 !important;
}
.input_ custom-input {
  width: 220px !important;
}
.input_ .input_1 {
  width: 70px !important;
}
.el-icon-arrow-down {
  position: absolute;
  top: 9px;
  left: 390px;
}
.left-text{
  line-height: 40.5px;
}
</style>
